import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 0.6rem;
  fill-rule: evenodd;
`

const LinkedinIcon = () => {
  return (
    <SVG viewBox="0 0 38 39">
      <g id="Page-1" stroke="none" fill="none">
        <g
          id="HOME-PAGE"
          transform="translate(-1160.000000, -2025.000000)"
          fill="#000000"
        >
          <g id="linkedin" transform="translate(1159.000000, 2025.000000)">
            <path
              d="M21.6041758,17.9417829 C21.6140659,17.9250686 21.6305495,17.9050114 21.6437363,17.8849543 L21.6437363,17.9417829 L21.6041758,17.9417829 Z M33.2316484,32.5734686 L27.5876923,32.5734686 L27.5876923,23.4173829 C27.5876923,21.1141543 26.7668132,19.55304 24.7228571,19.55304 C23.1635165,19.55304 22.2371429,20.6093829 21.8283516,21.6256114 C21.68,21.9933257 21.6437363,22.5047829 21.6437363,23.0095543 L21.6437363,32.5734686 L15.9931868,32.5734686 C15.9931868,32.5734686 16.069011,17.0626114 15.9931868,15.4680686 L21.6437363,15.4680686 L21.6437363,17.8849543 C22.3887912,16.72164 23.7305495,15.06024 26.7305495,15.06024 C30.4459341,15.06024 33.2316484,17.5072114 33.2316484,22.7688686 L33.2316484,32.5734686 Z M10.0492308,13.1213829 L10.012967,13.1213829 C8.11736264,13.1213829 6.88769231,11.80764 6.88769231,10.1662971 C6.88769231,8.49152571 8.15362637,7.21121143 10.0821978,7.21121143 C12.0140659,7.21121143 13.2074725,8.49152571 13.2371429,10.1662971 C13.2371429,11.80764 12.0140659,13.1213829 10.0492308,13.1213829 L10.0492308,13.1213829 Z M7.22395604,32.5734686 L12.8712088,32.5734686 L12.8712088,15.4613829 L7.22395604,15.4613829 L7.22395604,32.5734686 Z M36.1558242,0.846411429 L4.29978022,0.846411429 C2.7767033,0.846411429 1.53714286,2.06321143 1.53714286,3.56415429 L1.53714286,36.0366686 C1.53714286,37.5342686 2.7767033,38.7477257 4.29978022,38.7477257 L36.1558242,38.7477257 C37.6789011,38.7477257 38.9184615,37.5342686 38.9184615,36.0366686 L38.9184615,3.56415429 C38.9184615,2.06321143 37.6789011,0.846411429 36.1558242,0.846411429 L36.1558242,0.846411429 Z"
              id="Fill-3"
            ></path>
          </g>
        </g>
      </g>
    </SVG>
  )
}

export default LinkedinIcon
