import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 48px;
`

const ArtDirector = props => {
  return (
    <SVG viewBox="0 0 48 48">
      <circle id="Oval" cx="24" cy="24" r="24" fill="#eee" />
      <g id="noun_design_125975" transform="translate(12 12)">
        <g id="Group" transform="translate(0.625 1.25)">
          <path
            id="Shape"
            d="M0,22.221H0l.763-5.181L17.353.449a1.621,1.621,0,0,1,2.239,0l2.179,2.179a1.583,1.583,0,0,1,0,2.24L5.182,21.458,0,22.221ZM18.473,1.238a.334.334,0,0,0-.236.095L1.94,17.63l-.457,3.109,3.109-.457,16.3-16.3a.334.334,0,0,0,0-.473l-2.18-2.179A.334.334,0,0,0,18.473,1.238Z"
            transform="translate(0.199 0.392)"
          />
          <rect
            id="Rectangle"
            width="5.001"
            height="1.25"
            transform="translate(3.15 16.127) rotate(45)"
          />
          <rect
            id="Rectangle-2"
            data-name="Rectangle"
            width="5"
            height="1.25"
            transform="translate(16.591 2.685) rotate(45)"
          />
        </g>
        <g id="Group-2" data-name="Group">
          <path
            id="Path"
            d="M6.252,12.1,0,5.846l.884-.884L6.252,10.33l3.977-3.977L4.761.884,5.644,0,12,6.352Z"
            transform="translate(12.344 12.556)"
          />
          <path
            id="Path-2"
            data-name="Path"
            d="M6.389,12.134,0,5.745,5.745,0,12.1,6.349l-.884.884L5.745,1.768,1.768,5.745l5.5,5.505Z"
            transform="translate(0.034 0.347)"
          />
          <rect
            id="Rectangle-3"
            data-name="Rectangle"
            width="1.25"
            height="2.5"
            transform="translate(7.548 2.998) rotate(45)"
          />
          <rect
            id="Rectangle-4"
            data-name="Rectangle"
            width="1.25"
            height="2.5"
            transform="translate(10.199 5.65) rotate(45)"
          />
          <rect
            id="Rectangle-5"
            data-name="Rectangle"
            width="1.25"
            height="2.5"
            transform="translate(18.154 13.605) rotate(45)"
          />
          <rect
            id="Rectangle-6"
            data-name="Rectangle"
            width="1.25"
            height="2.5"
            transform="translate(20.805 16.256) rotate(45)"
          />
        </g>
      </g>
    </SVG>
  )
}

export default ArtDirector
