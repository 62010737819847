import React from 'react'
import styled from '@emotion/styled'

// @assets
import LinkedinIcon from '../icons/LinkedinIcon'
import IgIcon from '../icons/IgIcon'
import DribbleIcon from '../icons/DribbleIcon'
import BehanceIcon from '../icons/BehanceIcon'

const SocialList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0;
  svg {
    width: 23px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex-direction: column;
    align-itmes: space-between;
    margin: 32px 0 0 80px;
    li {
      &:hover {
        svg {
          path {
            fill: ${props => props.theme.colors.secondary};
          }
        }
      }
    }
    svg {
      width: 37px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 0 0 0 80px;
  }
`

const SocialMedia = () => (
  <SocialList>
    <li>
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href="https://www.linkedin.com/in/hector-saavedra89/"
      >
        <LinkedinIcon />
      </a>
    </li>
    <li>
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href="https://www.instagram.com/save_signer"
      >
        <IgIcon />
      </a>
    </li>
    <li>
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href="https://dribbble.com/SAVE1989"
      >
        <DribbleIcon />
      </a>
    </li>
    <li>
      <a
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        href="https://www.behance.net/hsaavedra"
      >
        <BehanceIcon />
      </a>
    </li>
  </SocialList>
)

export default SocialMedia
