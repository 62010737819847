import React from 'react'
import styled from '@emotion/styled'

const SVG = styled.svg`
  padding: 0;
  width: 48px;
`

const Ux = props => {
  return (
    <SVG viewBox="0 0 48 48">
      <circle id="Oval" cx="24" cy="24" r="24" fill="#eee" />
      <g
        id="noun_coffee_cup_1969378_1_"
        data-name="noun_coffee cup_1969378 (1)"
        transform="translate(9 9)"
      >
        <rect id="Rectangle" width="31" height="31" fill="none" />
        <path
          id="Shape"
          d="M9.574,24.618c-2.829,0-6.439-.422-7.3-1.613a.48.48,0,0,1-.2-.352L.913,5.206H.5A.5.5,0,0,1,0,4.714V2.752a.5.5,0,0,1,.5-.491h.76L2.433.246A.5.5,0,0,1,2.862,0H16.285a.5.5,0,0,1,.429.245L17.892,2.26h.758a.5.5,0,0,1,.5.492V4.714a.5.5,0,0,1-.5.491h-.415L17.073,22.653a.478.478,0,0,1-.2.352c-.409.562-1.444.992-3.075,1.277A26.065,26.065,0,0,1,9.574,24.618ZM3.067,22.429c.092.207.673.469,1.516.683a20.6,20.6,0,0,0,4.99.522,20.582,20.582,0,0,0,4.991-.522c.839-.213,1.419-.474,1.515-.681l0-.011,0,.006a.227.227,0,0,1,.009-.043c0-.01.006-.019.007-.029l.015-.227.273-4.1H2.76l.274,4.1.015.227a.294.294,0,0,0,.007.031.235.235,0,0,1,.008.038l0,0a.02.02,0,0,1,0,.012v0ZM16.052,11.218v5.827h.4l.388-5.827Zm-1.5,0v5.827h.5V11.218Zm-1.495,0v5.827h.5V11.218Zm-1.5,0v5.827h.5V11.218Zm-1.5,0v5.827h.5V11.218Zm-1.495,0v5.827h.5V11.218Zm-1.5,0v5.827h.5V11.218Zm-1.5,0v5.827h.5V11.218Zm-1.5,0v5.827h.5V11.218Zm-1.779,0,.387,5.827h.4V11.218Zm-.4-6.012.335,5.028H16.9l.335-5.028ZM.994,3.244v.978H18.153V3.244ZM3.148.984,2.4,2.261H16.745L16,.984H3.148Z"
          transform="translate(6.382 3.647)"
        />
      </g>
    </SVG>
  )
}

export default Ux
