// @vendors
import React from 'react'
import styled from '@emotion/styled'

// @components
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import SocialMedia from '../components/SocialMedia'
import useStaticMetadata from '../hooks/useMetadata'

// @icons
import Ux from '../icons/Ux'
import ArtDirector from '../icons/ArtDirector'

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .sloganDesktop {
    display: none;
  }
  h2 {
    font-size: 1.846rem;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 25px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    h2 {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    flex-direction: row;
    margin: 80px 0 0;

    h2 {
      display: none;
    }
    .sloganDesktop {
      display: block;
      font-size: 2rem;
    }
  }
`
const Saveprofile = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    flex-basis: 15%;
    margin-right: 48px;
  }
`
const SaveInfo = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 1.538rem;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .title {
    font-size: 1rem;
    span {
      display: none;
    }
    span:first-child {
      display: inline-block;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 55px 0;
    & > div {
      padding: 19px 0;
      border-bottom: 2px solid #646464;
    }
    .title {
      max-width: 80%;
      span {
        display: inline-block;
        line-height: 25px;
        margin-right: 5px;
      }
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
    border: 0;
    .title {
      display: none;
    }
    & > div {
      border: 0;
    }
  }
`
const ProfileImg = styled.img`
  border-radius: 50%;
  height: 70px;
  margin: 0 20px 0 0;
  width: auto;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    height: 118px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: 110px;
    margin: 0;
  }
`
const SaveSkills = styled.ul`
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  margin: 20px 0 45px;
  padding: 22px 0 0;

  li {
    font-size: 1.231rem;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 25px;
  }

  svg {
    margin: 0 34px 0 10px;
  }
  li:first-child {
    display: none;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: none;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    svg {
      display: none;
    }
    li:first-child {
      display: block;
    }

    li {
      border-top: 1px solid #979797;
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 25px;
      padding: 18px 0 18px;
      margin: 0;
      max-width: 139px;
    }
  }
`
const ContactFormWrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: flex;
    justify-content: flex-end;
  }
`

const Contact = ({ data }) => {
  const { contacto } = useStaticMetadata()
  return (
    <Layout>
      <SEO
        title={contacto.title}
        description={contacto.description}
        image="/images/meta-contacto.jpg"
      />
      <Container>
        <ContactWrapper>
          <Saveprofile>
            <SaveInfo>
              <ProfileImg src="/images/profile.png" />
              <div>
                <p>Héctor Saavedra P.</p>
                <div className="title">
                  <span>Diseñador Gráfico Profesional</span>
                  <span>{` - Diseñador UX / UI Senior`}</span>
                  <span>Director de Arte Senior</span>
                </div>
              </div>
            </SaveInfo>
            <SaveSkills>
              <li>Diseñador Gráfico Profesional</li>
              <li>
                <Ux />
                Diseñador UX / UI Senior
              </li>
              <li>
                <ArtDirector />
                Director de Arte Senior
              </li>
            </SaveSkills>
          </Saveprofile>
          <div className="middlePane">
            <h2>Adopta una Idea, Adapta una Imagen</h2>
            <ContactFormWrapper>
              <div>
                <h2 className="sloganDesktop">
                  Adopta una Idea, Adapta una Imagen
                </h2>
                <ContactForm />
              </div>

              <SocialMedia />
            </ContactFormWrapper>
          </div>
        </ContactWrapper>
      </Container>
    </Layout>
  )
}

export default Contact
